import React, {useEffect, useMemo, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "../../common/components/ui/button";
import { useAppSelector } from "../../hooks";
import { Link, useNavigate } from "react-router-dom";
import { PROJECT_STATUS, USER_TYPES } from "../../lib/constants";
import { useProjectAPI } from "../../api/project_api";
import { useDispatch } from "react-redux";
import { updateProjects, updateProjectsSubmittedForApprovalCount } from "../project/projectsSlice";
import {projectWizardSlice} from "../../pages/project/projectWizardSlice";
import { ProjectWizardPanels} from "../../pages/project/project_wizard";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function AppHeader({ minimalHeader, colorScheme }: { minimalHeader?: boolean, colorScheme?: "home"|"dark"|"lite" }) {
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
  const { profile } = useAppSelector((state: any) => state.user);
  const { resetProjectWizard } = projectWizardSlice.actions;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { fetchProjects } = useProjectAPI();

  useEffect(() => {
    if (isAuthenticated) {
      fetchProjects().then((projects) => {
        dispatch(updateProjects(projects));
        const count = projects.filter(
          (p) =>
            p.status &&
            [PROJECT_STATUS.SUBMITTED_FOR_APPROVAL.key, PROJECT_STATUS.CHANGES_REQUESTED.key].includes(p.status)
        )?.length;
        dispatch(updateProjectsSubmittedForApprovalCount(count));
      });
    }
  }, [isAuthenticated]);

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const userNavigation = [
    { name: "Your Profile", href: "/profile" },
    // { name: "Settings", href: "/settings" },
    { name: "Sign out", href: "#", onClick: handleLogout },
  ];

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = (event: any) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const bgClassName = useMemo(() => {
    if (scrollTop > 80) {
      return "bg-brand-black/90 shadow-md";
    }
    return "bg-none";
  }, [scrollTop]);

  return (
    <Disclosure as="nav" className="sticky relative top-0 z-50 mx-auto  w-full ">
      {({ open }) => (
        <div className={`absolute w-full ${bgClassName}`}>
          {minimalHeader ? (
            <div className="flex items-center justify-center h-20">
              <Link to="/">
                <img className="h-8 " src={colorScheme === "home" || colorScheme === "lite"? "/officialai_logo_black.svg": "/officialai_logo_white.svg"} alt="The Official AI" />
              </Link>
            </div>
          ) : (
            <div className="page_header">
              <div className="flex h-20 items-center justify-between z-50">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Link to="/">
                      <img className="h-8 " src="/officialai_logo_black.svg" alt="The Official AI" />
                    </Link>
                  </div>
                </div>
                {!isLoading && (
                  <>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        {isAuthenticated && profile.user_type === USER_TYPES.CREATOR.key && (
                          <div className="ml-4 mr-4">
                            <Button variant="secondary" onClick={() => {
                              dispatch(resetProjectWizard());
                              navigate("/project/new")}}>
                              Create project
                            </Button>
                          </div>
                        )}
                        {isAuthenticated && profile.user_type === USER_TYPES.RIGHTS_HOLDER.key && (
                          <div className="ml-4 mr-4">
                            <Button variant="secondary" onClick={() => navigate("/add-talent")}>
                              Add talent
                            </Button>
                          </div>
                        )}
                        {/*{isAuthenticated && user && (*/}
                        {/*  <button*/}
                        {/*    type="button"*/}
                        {/*    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"*/}
                        {/*  >*/}
                        {/*    <span className="absolute -inset-1.5" />*/}
                        {/*    <span className="sr-only">View notifications</span>*/}
                        {/*    <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                        {/*  </button>*/}
                        {/*)}*/}
                        {/* Profile dropdown */}
                        {isAuthenticated && user && (
                          <Menu as="div" className="relative">
                            <div className="text-white">
                              <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="absolute -inset-1.5" />
                                <span className="sr-only">Open user menu</span>
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={profile.avatar}
                                  alt="user profile picture"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={React.Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {userNavigation.map((item) => (
                                  <Menu.Item key={item.name}>
                                    {({ active }) => (
                                      <Link
                                        to={item.href}
                                        onClick={item.onClick}
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-650"
                                        )}
                                      >
                                        {item.name}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        )}
                        {!isAuthenticated && (
                          <Menu>
                            <div className="flex gap-[32px]">
                              <Button href="/check-license" variant="outline">
                                Verify License
                              </Button>
                              <Button href="/register" variant="secondary">
                                Get Started
                              </Button>
                              <Button onClick={() => loginWithRedirect()} variant="link">
                                Log in
                              </Button>
                            </div>
                          </Menu>
                        )}
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                  </>
                )}
              </div>
              <Disclosure.Panel className="md:hidden">
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={profile.avatar} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">{profile.full_name}</div>
                      <div className="text-sm font-medium leading-none text-gray-400">{profile.email}</div>
                    </div>
                    <button
                      type="button"
                      className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-650 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </div>
      )}
    </Disclosure>
  );
}
