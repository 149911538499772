import React, { useCallback } from "react";
import WizardStep from "../../../common/components/wizard_panel";
import FileManager from "../../../common/components/ui/file_manager";
import { UseFormReturn } from "react-hook-form";
import { UploadedFile } from "../../../api/file_api";
import { uniqueId } from "lodash";

export const ImageUpload = ({ form, field, errors }: { form: UseFormReturn; field: string; errors: any }) => {
  const uploadedFiles = form.watch("files");
  const objectId = form.watch("id") ?? uniqueId();
  const currentFieldValue = form.watch(field) ?? null;

  const onUploadedFilesChange = useCallback(
    (files: UploadedFile[]) => {
      form.setValue("files", files);
      if (field && files[0]?.permalink) {
        form.setValue(field, files[0].permalink);
      }
    },
    [form, uploadedFiles]
  );

  const onFileChange = useCallback(
    (files: File[]) => {
      if (files) {
        form.setValue("files", files);
      }
    },
    [form, uploadedFiles]
  );

  const onFileRemove = (file: any) => {
    form.setValue(
      "files",
      uploadedFiles.filter((f: UploadedFile) => f.name !== file.name)
    );
  };

  return (
    <WizardStep>
      <div
        style={{ backgroundImage: `url(${currentFieldValue})`, backgroundPosition: "center", backgroundSize: "cover" }}
        className="gap-10 text-center text-brand-black items-center  rounded-xl mb-10 min-h-[80px] lg:w-[876px] bg-gray-100"
      >
        <FileManager
          uploadedFiles={uploadedFiles}
          files={uploadedFiles}
          multiple={true}
          objectType="artist"
          objectId={objectId}
          uploadOnSelect={true}
          onUploadedFilesChange={onUploadedFilesChange}
          onFileChange={onFileChange}
          onFileRemove={onFileRemove}
          hintText={"For best results, upload assets in a 3:1 aspect ratio, at least 1280px wide"}
        />
      </div>
    </WizardStep>
  );
};
