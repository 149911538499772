import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../hooks/store";
import { User } from "../model/user";

export interface UserState {
  profile: User;
  // uninitialized: no user input
  // unregistered: no user_type (account type)
  // onboarding: has user_type (account type) but no company_type / company_name
  // registered: required user input done
  status: "uninitialized" | "unregistered" | "onboarding" | "registered";
}

const initialState: UserState = {
  profile: {
    id: "",
    first_name: "",
    last_name: "",
    full_name: "",
    user_type: "",
    avatar: "",
    external_id: "",
    email: "",
    organization_name: "",
    organization_type: "",
    social_accounts: [],
  },
  status: "uninitialized",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<User>) => {
      state.profile = action.payload;
      state.status = "registered";
      if (action.payload.user_type === "unknown") {
        state.status = "unregistered";
      }
    },
  },
});

export const { setUserProfile } = userSlice.actions;

export const userProfile = (state: RootState) => state.user.profile;
export const user = (state: RootState) => state.user;

export default userSlice.reducer;
