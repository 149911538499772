import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosProgressEvent } from "axios";
import { ProjectFile } from "./project_api";

export interface FileUploadRequest {
  files: File[];
  is_public: boolean;
  object_id?: string;
  object_type: string;
  generate_thumbnail?: boolean;
  resize?: string;
}
export interface UploadedFile {
  id?: string;
  file_id?: string;
  name?: string;
  description?: string;
  permalink?: string;
  mime_type?: string;
  file_type?: string;
  size?: number;
  is_public?: boolean;
  usage?: string;
  status?: string;
  project_file_id?: string;
  thumbnail?: string;
  upload_percentage?: number;
}

export function useFileAPI() {
  const { getAccessTokenSilently } = useAuth0();

  const uploadFile = async (
    uploadRequest: FileUploadRequest,
    onUploadProgress: (progress: AxiosProgressEvent) => void
  ): Promise<UploadedFile[]> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<UploadedFile[]>((resolve, reject) => {
      const params = new URLSearchParams();
      params.append("is_public", uploadRequest.is_public.toString());
      params.append("object_type", uploadRequest.object_type);
      if (uploadRequest.object_id) {
        params.append("object_id", uploadRequest.object_id);
      }
      if (uploadRequest.generate_thumbnail) {
        params.append("create_thumbnail", uploadRequest.generate_thumbnail.toString());
      }
      if (uploadRequest.resize) {
        params.append("resize", uploadRequest.resize);
      }
      const formData = new FormData();
      uploadRequest.files.forEach((file) => {
        formData.append("files", file);
      });
      //Need to use axios instead of fetch to support progress events
      axios
        .post(`${process.env.REACT_APP_API_URL}/file/upload?${params.toString()}`, formData, {
          onUploadProgress: onUploadProgress,
          headers: {
            authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error("Failed to upload"));
          }
        });
    });
  };

  const getPresignedUrl = async (file: ProjectFile): Promise<any | null> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });
    const url = `${process.env.REACT_APP_API_URL}/file/${file.id}/presigned-url`;

    return new Promise<string>((resolve, reject) => {
      fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then(async (response) => {
        if (response.status === 200) {
          const res = await response.text();
          resolve(res);
        } else {
          reject(new Error("User is not authenticated"));
        }
      });
    });
  };

  return { uploadFile, getPresignedUrl };
}
