import React, { useCallback, useState } from "react";
import WizardSetup from "../../../common/components/wizard_panel";
import FileManager from "../../../common/components/ui/file_manager";
import { UploadedFile } from "../../../api/file_api";
import { uniqueId } from "lodash";
import { Button } from "../../../common/components/ui/button";

export const ArtistAddTrainingData = ({ form, field, onUploadComplete }: { form: any; field: string; onUploadComplete: (idx: any) => void }) => {
    const [activeTab, setActiveTab] = useState<"instructions" | "why">("instructions");
    const uploadedFiles = form.watch("files")?.filter((file: UploadedFile) => file.usage === "training");
    const objectId = form.watch("id") ?? uniqueId();

    const onUploadedFilesChange = useCallback((uploadedFiles: UploadedFile[]) => {
        const existingFiles = form.getValues("files");
        uploadedFiles.forEach((it) => {
            it.file_id = it.id;
            it.usage = "training";
        });
        if (existingFiles != null) {
            form.setValue("files", [...existingFiles, ...uploadedFiles]);
        } else {
            form.setValue("files", uploadedFiles);
        }
        onUploadComplete(7);
    }, [form]);

    const onFileChange = useCallback(
        (files: File[]) => {
            if (files) {
                form.setValue("files", files);
            }
        },
        [form, uploadedFiles]
    );

    const onFileRemove = (file: any) => {
        form.setValue(
            "files",
            uploadedFiles.filter((f: UploadedFile) => f.name !== file.name)
        );
    };

    return (
        <WizardSetup>
            <FileManager
                uploadedFiles={uploadedFiles}
                files={uploadedFiles}
                multiple={true}
                objectType="artist"
                objectId={objectId}
                uploadOnSelect={true}
                onUploadedFilesChange={onUploadedFilesChange}
                onFileChange={onFileChange}
                onFileRemove={onFileRemove}
            />
            <div className="flex pt-10 px-5 gap-5">
                <Button className={activeTab === "instructions" ? "bg-brand-yellow" : "bg-white"} variant={activeTab === "instructions" ? "primary-negative-invert" : "outline-negative"} onClick={() => setActiveTab("instructions")}>
                    Instructions
                </Button>
                <Button className={activeTab === "why" ? "bg-brand-yellow" : "bg-white"} variant={activeTab === "why" ? "primary-negative-invert" : "outline-negative"} onClick={() => setActiveTab("why")}>
                    Why we need it
                </Button>
            </div>
            {activeTab === "instructions" && <div className="p-5">
                <p>At a minimum we are asking the talent to provide a MINIMUM of 10, (30 ideal) images.  These images should include the following features:</p>
                <ol className="list-decimal list-inside">
                    <li>No other humans in the image besides them</li>
                    <li>A mixture of facial expressions (i.e. some smiling, some neutral, some concentrating).</li>
                    <li>A mixture of facial angles (i.e. one looking at the camera, one looking to the side, one left and right face profile, one looking over their shoulder)</li>
                    <li>At least two full body shots (more if an athlete or distinctive body shape)</li>
                    <li>Some variety of clothing is helpful</li>
                    <li>File Types Supported: Audio: .wav, .mp3, .aiff, Images: .jpg, .png, .tiff, Video: .mp4, .mov, .avi</li>
                </ol>
            </div>}
            {activeTab === "why" && <div className="p-5">This training data will be used to generate AI content that aligns with your unique identity, empowering you to maintain control over your digital representation.</div>}
        </WizardSetup>
    )
}